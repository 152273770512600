import { nanoid } from 'nanoid'

let token = ''
let initEventSent = false
const uiid = nanoid()
window.uiid = uiid
const searchParam = new URLSearchParams(window.location.search)
const sourceParam = searchParam.get('utm_source')

function getBrowserInfo () {
  const ua = navigator.userAgent
  const browserInfo = {
    userAgent: ua,
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: window.devicePixelRatio
    },
    // Parse basic browser/OS info from UA string
    browser: 'unknown',
    os: 'unknown'
  }

  // Basic browser detection
  if (ua.includes('Firefox/')) {
    browserInfo.browser = 'Firefox'
  } else if (ua.includes('Chrome/')) {
    browserInfo.browser = 'Chrome'
  } else if (ua.includes('Safari/') && !ua.includes('Chrome/')) {
    browserInfo.browser = 'Safari'
  } else if (ua.includes('Edge/')) {
    browserInfo.browser = 'Edge'
  }

  // Basic OS detection
  if (ua.includes('Windows')) {
    browserInfo.os = 'Windows'
  } else if (ua.includes('Mac OS')) {
    browserInfo.os = 'MacOS'
  } else if (ua.includes('Linux')) {
    browserInfo.os = 'Linux'
  } else if (ua.includes('Android')) {
    browserInfo.os = 'Android'
  } else if (ua.includes('iOS')) {
    browserInfo.os = 'iOS'
  }

  return browserInfo
}

function getMainSite () {
  return document.querySelector('meta[name="main-site"]').getAttribute('content')
}

// Cached browser info to avoid recalculation
const cachedBrowserInfo = getBrowserInfo()

// Function to push events to the dataLayer
function pushToDataLayer (eventName, eventData) {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      window.dataLayer.push({
        event: eventName,
        ...eventData
      })
    } catch (e) {
      // silent error
      // console.error(`Failed to push to dataLayer: ${e}`)
    }
  } else {
    // silent error
    // console.warn('window.dataLayer not found')
  }
}

function sendAcquisitionEvent (eventData) {
  const mainSite = getMainSite()
  const url = `${mainSite}/api/v1/acquisition_event`

  const enrichedEventData = {
    ...eventData,
    browserInfo: cachedBrowserInfo
  }

  const body = JSON.stringify(enrichedEventData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Csrf-Token': token
    },
    body: body
  })
    .then(response => response.json())
    .catch(() => { /* silence any errors */ })
}

function targetOrigin () {
  return getMainSite()
}

function postDataToWindow (targetWindow, sourceParam) {
  targetWindow.postMessage({
    type: 'marketingData',
    uuid: uiid,
    source: sourceParam,
    referrer: document.referrer,
    browserInfo: getBrowserInfo()
  }, targetOrigin())
}

function listener (event) {
  if (event.origin === targetOrigin()) {
    if (event.data.type === 'iFrameReady') {
      if (token === '') {
        token = event.data.token
      }
      postDataToWindow(event.source, sourceParam)

      if (token && !initEventSent) {
        sendAcquisitionEvent({
          dataSource: {
            uuid: uiid,
            source: sourceParam,
            referrer: document.referrer
          },
          stage: 'init'
        })
        initEventSent = true
      }
    }
  }
}

window.addEventListener('message', listener)

function handleLinkClick (event, callback) {
  event.preventDefault()
  const link = event.currentTarget
  const url = new URL(link.href)
  url.searchParams.set('uuid', uiid)

  callback().finally(() => {
    window.location.href = url.toString()
  })
}

function addEventListenerForAcqEvent (link, signUpEventSent) {
  link.addEventListener('click', (event) => {
    if (!signUpEventSent) {
      handleLinkClick(event, async () => {
        await sendAcquisitionEvent({
          dataSource: {
            uuid: uiid,
            source: sourceParam,
            referrer: document.referrer
          },
          entryPoint: 'landing_page',
          stage: 'click_sign_up_link'
        })

        pushToDataLayer('Acquisition', {
          content_category: 'user_acquisition',
          content_name: 'landing_page',
          content_type: 'click_sign_up_link'
        })

        signUpEventSent = true
      })
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  sendAcquisitionEvent({
    dataSource: {
      uuid: uiid,
      source: sourceParam,
      referrer: document.referrer
    },
    stage: 'page_load'
  })

  const signUpEventSent = false
  let heroSignUpEventSent = false

  const signUpLink1 = document.getElementById('sign-up-link-1')
  const signUpLink2 = document.getElementById('sign-up-link-2')

  if (signUpLink1) addEventListenerForAcqEvent(signUpLink1, signUpEventSent)
  if (signUpLink2) addEventListenerForAcqEvent(signUpLink2, signUpEventSent)

  const heroSignUpButton = document.getElementById('hero-sign-up')
  if (heroSignUpButton) {
    heroSignUpButton.addEventListener('click', (event) => {
      if (!heroSignUpEventSent) {
        handleLinkClick(event, async () => {
          await sendAcquisitionEvent({
            dataSource: {
              uuid: uiid,
              source: sourceParam,
              referrer: document.referrer
            },
            entryPoint: 'landing_page',
            stage: 'primary_CTA_clicked'
          })

          pushToDataLayer('Acquisition', {
            content_category: 'user_acquisition',
            content_name: 'landing_page',
            content_type: 'primary_CTA_clicked'
          })

          heroSignUpEventSent = true
        })
      }
    })
  }
})
